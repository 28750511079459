<template>
	<div>
		<v-layout class="align-center light-blue-bg px-4 py-2" style="min-height: 140px">
			<v-flex md12>
				<div class="d-flex justify-end">
					<v-btn v-on:click="goBack()" color="" class="ml-2 mb-1" depressed tile>
						<v-icon small left>mdi-arrow-left</v-icon>Back
					</v-btn>
				</div>
				<div class="d-flex justify-space-between">
					<div class="width-45 purchase-order-price-history-detail-box-1">
						<div class="font-level-4-bold px-4 py-3 d-flex" style="background-color: rgb(235 229 189)">
							<div>Product</div>
							<!-- <Chip
								:text="detail?.basic_detail?.product_barcode"
								tooltip
								tooltip-text="product #"
								
							></Chip> -->
							<v-tooltip top content-class="">
								<template v-slot:activator="{ on, attrs }">
									<v-chip
										v-bind="attrs"
										v-on="on"
										label
										text-color="white"
										color="cyan"
										class="ml-2 cursor-pointer"
									>
										{{ detail?.basic_detail?.product_barcode }}
									</v-chip>
								</template>
								<span> Product # </span>
							</v-tooltip>
						</div>
						<div class="px-4 mb-4 d-flex">
							<div class="mt-4 d-flex">
								<ImageTemplate circle style="max-width: 150px" :src="detail?.basic_detail?.product_image">
								</ImageTemplate>
							</div>

							<div class="mt-4 ml-6">
								<div class="mt-4">
									<span class="fw-600 mr-2">Name: </span>
									<span class="fw-500 no-wrap">
										<ShowValue :object="detail?.basic_detail" object-key="product_name" label="Product  Name">
										</ShowValue>
									</span>
								</div>
								<div class="mt-2">
									<span class="fw-600 mr-2">Category: </span>
									<span class="fw-500 no-wrap">
										<ShowValue
											:object="detail?.basic_detail"
											object-key="product_category_name"
											label="Product Category Name"
										>
										</ShowValue>
									</span>
								</div>
								<div class="mt-2">
									<span class="fw-600"> Type : </span>
									<span class="fw-500">
										<ShowValue
											:object="detail?.basic_detail"
											object-key="inventory_product_type"
											label="product type"
										>
										</ShowValue>
									</span>
								</div>
								<div class="mt-2">
									<span class="fw-600"> Serial No : </span>
									<span class="fw-500 no-wrap text--darken-4">
										<ShowValue
											:object="detail?.basic_detail"
											object-key="product_serial_number"
											label="product serial no"
										>
										</ShowValue>
									</span>
								</div>
							</div>
						</div>
					</div>
					<div class="width-45 purchase-order-price-history-detail-box-2">
						<div
							class="font-level-4-bold px-4 py-3 d-flex justify--between"
							style="background-color: #dbdfea"
						>
							Supplier
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-chip
										v-bind="attrs"
										v-on="on"
										label
										text-color="white"
										color="cyan"
										class="ml-2 cursor-pointer"
									>
										{{ detail?.basic_detail?.supplier_barcode }}
									</v-chip>
								</template>
								<span> Supplier # </span>
							</v-tooltip>
						</div>
						<div class="px-4 mb-4 d-flex">
							<div class="mt-4 d-flex">
								<ImageTemplate
									circle
									style="max-width: 150px"
									:src="detail?.basic_detail?.supplier_image_url"
								>
								</ImageTemplate>
							</div>
							<div class="mt-4 ml-6">
								<div class="mt-2">
									<span class="fw-600 mr-2">Supplier Name: </span>
									<span class="fw-500 no-wrap ml-2">
										<ShowValue
											:object="detail?.basic_detail"
											object-key="supplier_display_name"
											label="supplier  Name"
										>
										</ShowValue>
									</span>
								</div>
								<div class="mt-2">
									<span class="fw-600 mr-2">Company Name: </span>
									<span class="fw-500 no-wrap">
										<ShowValue
											:object="detail?.basic_detail"
											object-key="supplier_company_name"
											label="supplier company name"
										>
										</ShowValue>
									</span>
								</div>

								<div class="mt-2">
									<span style="font-weight: 800"> Updated Price : </span>
									<span style="font-weight: 800" class="no-wrap text--darken-4">
										<ShowPrice
											style="font-weight: 800"
											:object="detail?.basic_detail"
											object-key="supplier_latest_price"
											label="supplier latest price"
										>
										</ShowPrice>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</v-flex>
		</v-layout>
		<div>
			<div class="d-flex pb-2 mt-4 justify-space-between align-items-center">
				<div class="d-flex">
					<inline-svg
						fill="#2666cb"
						:src="$assetURL('media/custom-svg/expense-icon.svg')"
						height="20"
						width="20"
						class="mr-2"
					/>
					<h5 style="color: #0d47a1">Price History</h5>
				</div>
				<div style="width: 200px" class="mr-4">
					<AutoCompleteInput
						hide-details
						:items="purchaseOrderList"
						:disabled="pageLoading"
						:loading="pageLoading"
						placeholder="purchase order"
						item-text="barcode"
						item-value="id"
						v-model="selectedPurchaseOrder"
						@change="updatePagination()"
					></AutoCompleteInput>
				</div>
			</div>
			<div class="overflow-y price-history" style="max-height: calc(100vh - 460px); min-height: 350px">
				<v-simple-table v-if="!pageLoading" class="bt-table listing-table table-head-sticky">
					<thead>
						<tr>
							<th width="50">#</th>
							<th v-for="(th, key) in thead" :key="key" :width="th.width">
								<template>
									{{ th.name }}
								</template>
							</th>
						</tr>
					</thead>
					<tbody v-if="detail?.tbody.length">
						<tr class="cursor-pointer" v-for="(row, index) in detail?.tbody" :key="index">
							<td>{{ index + 1 }}</td>
							<td width="250px">
								<ShowPrice :object="row" object-key="rate" label="new price"></ShowPrice>
							</td>
							<td>
								<ShowPrice :object="row" object-key="old_rate" label="old price"></ShowPrice>
							</td>
							<td v-on:click.stop.prevent="goToPurchaseOrder(row)">
								<div>
									<v-chip class="mt-1" color="cyan" outlined small label>
										{{ row.parent_barcode }}
									</v-chip>
								</div>
								<ShowValue :object="row" object-key="type_value" label="type"></ShowValue>
							</td>
							<td>
								<ShowValue :object="row" object-key="uom" label="uom"></ShowValue>
							</td>
							<td>
								<ShowValue :object="row" object-key="quantity" label="quantity"></ShowValue>
							</td>
							<td>
								<ShowValue :object="row" object-key="description" label="description"></ShowValue>
							</td>
							<td>
								<TableDateTime
									small
									v-if="row.added_at"
									:human-format="row.added_at_formatted"
									:system-format="row.added_at"
								></TableDateTime>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="11">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no price history at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
				<div v-else class="mx-20 my-40 text-center">
					<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
				</div>
			</div>
			<v-row class="my-2">
				<v-col md="6" class="my-auto">
					<p class="m-0">{{ showingString }}</p>
				</v-col>
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="page"
						:length="total_page"
						total-visible="6"
						v-on:input="updatePagination($event)"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
import ShowValue from "@/view/components/ShowValue";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { getPurchaseOrderSupplier } from "@/core/lib/purchase-order.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import ShowPrice from "@/view/components/ShowPrice";
import ImageTemplate from "@/view/components/Image";
import TableDateTime from "@/view/components/TableDateTime";
export default {
	name: "expense-listing",
	title: "expense Listing",
	components: { AutoCompleteInput, ShowValue, ShowPrice, ImageTemplate, TableDateTime },
	data() {
		return {
			totalPage: 5,
			purchaseOrderExpense: [],
			budget: null,
			thead: null,
			page: 1,
			detail: null,
			total_page: null,
			pageLoading: true,
			purchaseOrderList: [],
			selectedPurchaseOrder: 0,
		};
	},
	methods: {
		async getExpenseById() {
			this.pageLoading = true;
			try {
				this.expenseDetailDialog = true;
				const data = await getPurchaseOrderSupplier({
					product_uuid: this.$route.params.uuid,
					supplier_id: this.$route.params.supplier_id,
					per_page: 15,
					page: this.page,
					purchaser_order: this.selectedPurchaseOrder,
				});
				this.detail = data;
				this.total_page = data.total_page;
				this.thead = data.thead;
				this.purchaseOrderList = data.purchase_order_dropdown;
				this.detail = data;
				this.$store.dispatch(SET_BREADCRUMB, [
					{
						text: "Product",
						disabled: false,
						href: `/product`,
					},
					{
						text: this.detail?.basic_detail?.product_barcode,
						disabled: false,
						href: `/product/${this.$route.params.uuid}?t${new Date().getTime()}&tab=suppliers`,
					},
					{ text: "Price History", disabled: true, href: "#" },
				]);
				// this.purchaseOrderExpense = data?.purchase_orders;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async updatePagination() {
			await this.getExpenseById();
		},
		async goToPurchaseOrder(row) {
			this.$router.push({
				name: "purchase-order-detail",
				params: { uuid: row?.purchase_order_uuid },
			});
		},
	},
	computed: {
		...mapGetters(["showingString"]),
	},
	async mounted() {
		await this.getExpenseById();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
